import React from "react"
import BasicPage from "../components/PageWrapper/BasicPage"

const GewinnspielTeilnahmebedingungen = (props: any) => {
  return (
    <>
      <BasicPage
        title="Gewinnspiel Teilnahmebedingungen"
        location={props.location}
        noindex={true}
      >
        <section className="container bg-white py-8 md:px-6 mx-auto max-w-3xl">
          <h1>Teilnahmebedingungen smartificate GmbH Gewinnspiel</h1>

          <p>
            Das Gewinnspiel wird von smartificate GmbH veranstaltet.
            Teilnahmeberechtigt ist jeder Kunde der smartificate GmbH mit
            Wohnsitz in Deutschland, der das 18. Lebensjahr vollendet hat. Das
            Gewinnspiel beginnt am 28.04.2022; Teilnahmeschluss des Gewinnspiels
            ist der 31.05.2022 um 23:59 Uhr. Verspätete Teilnahmen können nicht
            berücksichtigt werden.
          </p>

          <p>
            Die Teilnahme am Gewinnspiel ist nur durch die persönliche
            Eintragung des Teilnehmers/der Teilnehmerin zulässig. Die Teilnahme
            über automatisierte Verfahren (z. B. über Gewinnspielclubs oder
            Gewinnspielservice-Anbieter) ist unzulässig. Wir behalten uns in
            diesen Fällen und bei Verstößen gegen die Teilnahmebedingungen oder
            Manipulationen den Ausschluss der betreffenden Teilnehmer vor.
          </p>

          <p>
            Mehrere Teilnahmen derselben Person werden als eine Teilnahme am
            Gewinnspiel gewertet.
          </p>

          <h2>Gewinnbeschreibung</h2>

          <p>Zu gewinnen gibt es 1x 100 EUR.</p>

          <p>
            Der Gewinner wird im Anschluss an das Gewinnspiel mit Hilfe eines
            Zufallsgenerators ermittelt und per E-Mail kontaktiert.
          </p>

          <p>
            Der Gewinner hat sich bis zum 15.06.2022 um 23:59 Uhr zurückzumelden
            und die Kontoverbindung für die Überweisung des Gewinns
            mitzuteilen.In dem Fall, dass sich ein Gewinner nicht bis zum
            15.06.2022 um 23:59 Uhr eigenständig mit smartificate GmbH in
            Verbindung setzt, verfällt der Gewinn und der Gewinner verliert
            seinen Anspruch auf eine Übersendung des Gewinns. Die Bekanntgabe
            der Gewinner erfolgt ohne Gewähr.
          </p>

          <p>
            smartificate GmbH ist berechtigt, die persönlichen Daten an Dritte
            zu übermitteln, um eine Auslieferung des Gewinns zu ermöglichen. Pro
            Teilnehmer ist nur ein Gewinn möglich, der nicht alternativ in bar
            ausgezahlt werden kann. Gewinnansprüche sind nicht auf andere
            Personen übertragbar. smartificate GmbH wird mit Aushändigung des
            Gewinns von allen Verpflichtungen frei. Für Rechts- und Sachmängel
            haftet smartificate GmbH nur bei Vorsatz oder grober Fahrlässigkeit.
          </p>

          <h2>Vorzeitige Beendigung des Gewinnspiels</h2>

          <p>
            smartificate GmbH behält sich vor, das Gewinnspiel zu jedem
            Zeitpunkt ohne Vorankündigung abzubrechen oder zu beenden. Von
            dieser Möglichkeit macht smartificate GmbH insbesondere dann
            Gebrauch, wenn aus technischen Gründen (z. B. Viren im
            Computersystem, Manipulation oder Fehler in der Hard- und/oder
            Software) oder aus rechtlichen Gründen eine ordnungsgemäße
            Durchführung des Gewinnspiels nicht gewährleistet werden kann.
            Sofern eine derartige Beendigung durch das Verhalten eines
            Teilnehmers verursacht wurde, so kann smartificate GmbH von dieser
            Person Ersatz für den entstandenen Schaden verlangen.
          </p>

          <h2>
            Anwendbares Recht; Salvatorische Klausel; Ausschluss des Rechtswegs;
            Änderungsrecht
          </h2>

          <p>
            Diese Teilnahmebedingungen und die gesamte Rechtsbeziehung zwischen
            den Teilnehmern und smartificate GmbH unterliegen ausschließlich dem
            Recht der Bundesrepublik Deutschland. Sollten einzelne Bestimmungen
            der Teilnahmebedingungen ungültig sein oder werden, bleibt die
            Gültigkeit der übrigen Bestimmungen unberührt. Lückenhafte oder
            unwirksame Bestimmungen sind so zu ergänzen oder zu ersetzen, dass
            eine andere angemessene Regelung gefunden wird, die wirtschaftlich
            dem am nächsten kommt, was dem Sinn und Zweck der unwirksamen oder
            lückenhaften Bestimmung entspricht. Der Rechtsweg ist
            ausgeschlossen.
          </p>

          <h2>Datenschutz</h2>

          <p>
            Die Datenschutzerklärung der smartificate GmbH ist über folgenden
            Link abrufbar:{" "}
            <a href="https://smartificate.de/datenschutz/">
              Datenschutzerklärung
            </a>
          </p>

          <h2>Impressum</h2>

          <p>
            Das Impressum der smartificate GmbH ist über folgenden Link
            abrufbar: <a href="https://smartificate.de/impressum">Impressum</a>
          </p>
        </section>
      </BasicPage>
    </>
  )
}

export default GewinnspielTeilnahmebedingungen
